<template>
  <v-col class="d-flex justify-center">
    <v-carousel
      cycle
      hide-delimiters
      show-arrows-on-hover
    >
      <template v-slot:prev="{ on, attrs }">
        <v-btn
          color="primary"
          fab
          small
          v-bind="attrs"
          v-on="on"
        ><v-icon>fas fa-caret-left</v-icon></v-btn>
      </template>
      <template v-slot:next="{ on, attrs }">
        <v-btn
          color="primary"
          fab
          small
          v-bind="attrs"
          v-on="on"
        ><v-icon>fas fa-caret-right</v-icon></v-btn>
      </template>

      <v-carousel-item
        v-for="(item,i) in items"
        :key="i"
        :src="item.src"
        contain
      ></v-carousel-item>
    </v-carousel>
  </v-col>
</template>

<script>
  export default {
    data () {
      return {
        items: [
          { src: require('../assets/img/local1.jpg') },
          { src: require('../assets/img/local2.jpg') },
          { src: require('../assets/img/local3.jpg') },
          { src: require('../assets/img/local4.jpg') },
          { src: require('../assets/img/local5.jpg') },
          { src: require('../assets/img/local6.jpg') },
          { src: require('../assets/img/local7.jpg') },
          { src: require('../assets/img/local8.jpg') },
          { src: require('../assets/img/local9.jpg') },
          { src: require('../assets/img/local10.jpg') },
          { src: require('../assets/img/local11.jpg') },
          { src: require('../assets/img/local12.jpg') },
          { src: require('../assets/img/local13.jpg') },
          { src: require('../assets/img/local14.jpg') },
          { src: require('../assets/img/local21.jpg') },
          { src: require('../assets/img/local23.jpg') }
        ]
      }
    }
  }
</script>