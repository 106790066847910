<template>
  <v-navigation-drawer
    v-model="drawer"
    class="secondary accent-4"
    :permanent="$vuetify.breakpoint.mdAndUp"
    dark
    app
  >
    <v-col class="col-12 text-center">
      <h1 class="font-weight-light white--text">SISTEMAS</h1>
    </v-col>
    <v-divider></v-divider>

    <v-list>
    <div v-for="(item, index) in items" :key="index">

      <v-list-group color="accent" no-action
      
      >
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title class="font-weight-bold">
              {{ item.nombre }}
            </v-list-item-title>
          </v-list-item-content>
        </template>

        <v-icon slot="appendIcon" small>fas fa-chevron-down</v-icon>

          <v-list-item
            v-for="item in item.items"
            :key="item.title"
            :href="item.href"
            target="_blank"
            link
            dense
            class="pl-4"
          >
            <v-list-item-icon class="mr-2">
              <v-icon small>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
      </div>
    </v-list>

    <template v-slot:append>
      <div class="pa-3 text-center">
        <v-btn
          color="primary"
          block
          href="https://webmail.borigenbetzel.com.ar/"
        >
          Webmail
        </v-btn>
      </div>
      <v-divider></v-divider>
      <p class="text-center white--text caption mt-1">
        Copyright © Borigen Betzel {{ new Date().getFullYear() }}
      </p>
    </template>
  </v-navigation-drawer>
</template>

<script>
export default {
  data() {
    return {
      items: [
        {
          nombre: "CELUSHOP",
          items: [
            {
              title: "JARVIS",
              href: "http://200.45.250.4:8800/bb_web/public",
              icon: "fas fa-chevron-circle-right",
            },
            {
              title: "JARVIS 2.0",
              href: "https://jarvis2.borigenbetzel.com.ar",
              icon: "fas fa-chevron-circle-right",
            },

            {
              title: "ECOMMERCE",
              href: "https://celushop.com.ar",
              icon: "fas fa-chevron-circle-right",
            },
            {
              title: "PAGOS",
              href: "https://pagos.celushop.com.ar",
              icon: "fas fa-credit-card",
            },
          ],
        },

        {
          nombre: "DYCAR",
          items: [
            {
              title: "STARK DYCAR",
              href: "https://stark.dycar.com.ar",
              icon: "fas fa-chevron-circle-right",
            },
            {
              title: "USADOS DYCAR",
              href: "https://usados.dycar.com.ar",
              icon: "fas fa-chevron-circle-right",
            },
            {
              title: "PAGOS",
              href: "https://pagos.dycar.com.ar",
              icon: "fas fa-credit-card",
            },
          ],
        },

        {
          nombre: "EURODYCAR",
          items: [
            {
              title: "STARK EURODYCAR",
              href: "https://stark.eurodycar.com.ar",
              icon: "fas fa-chevron-circle-right",
            },
            {
              title: "USADOS EURODYCAR",
              href: "https://usados.eurodycar.com.ar",
              icon: "fas fa-chevron-circle-right",
            },
            {
              title: "PAGOS",
              href: "https://pagos.eurodycar.com.ar",
              icon: "fas fa-credit-card",
            },
          ],
        },

        {
          nombre: "RADA",
          items: [
            {
              title: "ULTRON",
              href: "https://ultron.radarentacar.com.ar",
              icon: "fas fa-chevron-circle-right",
            },
            {
              title: "PAGOS",
              href: "https://pagos.radarentacar.com.ar",
              icon: "fas fa-credit-card",
            },
          ],
        },
      ],
    };
  },
  props: {
    value: Boolean,
  },
  computed: {
    drawer: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>
