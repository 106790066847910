<template>
  <v-app>
    <Drawer v-model="drawer" />
    <v-main>
    <v-app-bar
      v-if="$vuetify.breakpoint.smAndDown"
      color="primary"
      dense
      dark
    >
      <v-app-bar-nav-icon
        @click.stop="drawer = !drawer"
      ></v-app-bar-nav-icon>
    </v-app-bar>
      <div class="d-flex justify-center">
        <v-img src="./assets/footer.jpg" width="100%" max-width="849px"></v-img>   
      </div>
      <v-progress-linear value="100" color="primary"></v-progress-linear>
      <Caroucel />
    </v-main>
  </v-app>
</template>

<script>
import Drawer from './components/Drawer'

import Caroucel from './components/Caroucel'

export default {
  name: 'App',
  data () {
    return {
      drawer: false
    }
  },
  components: {
    Drawer,
    Caroucel,
  }
}
</script>
